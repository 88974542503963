import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import MainLayout from './components/layouts/MainLayout';

const AppRoutes: React.FC = () => (
    <Routes>
        <Route path="/" element={<MainLayout menu="1"><Home /></MainLayout>} />

    </Routes>
);

export default AppRoutes;
