import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'; // Assuming you have a CSS file for custom styling
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <Container className='footer-inner'>
        <Row className="justify-content-center text-center mb-4">
          <Col md={6}>
            <Image
              src="https://subra.ics.forth.gr/wp-content/uploads/2024/01/EN-Funded-by-the-EU-NEG-1024x215.png"
              alt=""
              className="img-eu"
            />
            <p className="py-3 mr-2">
              Views and opinions expressed are those of the authors and not necessarily reflect those of the European Union or the European Health and Digital Executive Agency. Neither the EU, nor the granting authority can be held responsible for them.
            </p>
          </Col>

          <Col md={6}>
            <ul className="list-unstyled">
              <li>
                <a href="https://subra.ics.forth.gr/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="icon-with-stroke" style={{textAlign:'left',fontWeight:"700",marginRight:'10px',WebkitTextStroke:'3px white'}}fontSize={8}icon={faChevronRight} color={'#2fd6ff' } /><div className='footer-text d-inline-flex'>Home</div>
                </a>
              </li>
              <li>
                <a href="https://subra.ics.forth.gr/stakeholders/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="icon-with-stroke" style={{textAlign:'left',fontWeight:"700",marginRight:'10px',WebkitTextStroke:'3px white'}}fontSize={8}icon={faChevronRight} color={'#2fd6ff' } /> 
                <div className='footer-text d-inline-flex'>Stakeholders</div>
                </a>
              </li>
              <li>
                <a href="https://subra.ics.forth.gr/citizens/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="icon-with-stroke" style={{textAlign:'left',fontWeight:"700",marginRight:'10px',WebkitTextStroke:'3px white'}}fontSize={8}icon={faChevronRight} color={'#2fd6ff' } /> 
                <div className='footer-text d-inline-flex'>Citizens </div>
                </a>
              </li>
              <li>
                <a href="http://subra.ics.forth.gr:3000/scientists" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="icon-with-stroke" style={{textAlign:'left',fontWeight:"700",marginRight:'10px',WebkitTextStroke:'3px white'}}fontSize={8}icon={faChevronRight} color={'#2fd6ff' } /> 
                <div className='footer-text d-inline-flex'>Scientists</div>
                </a>
              </li>
              <li>
                <a href="http://subra.ics.forth.gr:3000/tools/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="icon-with-stroke" style={{textAlign:'left',fontWeight:"700",marginRight:'10px',WebkitTextStroke:'3px white'}}fontSize={8}icon={faChevronRight} color={'#2fd6ff' } /> 
                <div className='footer-text d-inline-flex'> Tools</div>
                </a>
              </li>

              <li>
                <a href="https://subra.ics.forth.gr/privacy-policy/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="icon-with-stroke" style={{textAlign:'left',fontWeight:"700",marginRight:'10px',WebkitTextStroke:'3px white'}}fontSize={8}icon={faChevronRight} color={'#2fd6ff' } /> 
                <div className='footer-text d-inline-flex'>Privacy Policy</div>
                </a>
              </li>
              <li>
                <a href="https://subra.ics.forth.gr/cookies-policy/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className="icon-with-stroke" style={{textAlign:'left',fontWeight:"700",marginRight:'10px',WebkitTextStroke:'3px white'}}fontSize={8}icon={faChevronRight} color={'#2fd6ff' } /> 
                <div className='footer-text d-inline-flex'>Cookies Policy</div>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
