import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const AppHeader: React.FC = () => {
   const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({
      stakeholders: false,
      citizens: false,
    });
  
    const handleToggle = (key: string) => {
      setIsOpen(prevState => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    };
  
    return (
      <div className='bg-dark-blue'>
      <Navbar expand="lg" >
        <Container className="nav-inner">
          <Navbar.Brand href="https://subra.ics.forth.gr/">
            <img
              src="https://subra.ics.forth.gr/wp-content/uploads/2024/07/NIKHs-Logo-White-1.png"
              width="225px"
              className="d-inline-block align-top"
              alt="NIKH's Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav  activeKey="/" >
              <Nav.Link active href="/">Home</Nav.Link>
              <NavDropdown 
                title={
                  <>
                    <span style={{color: isOpen.stakeholders ?  '#2fd6ff':'white',fontWeight:400 }} >Stakeholders <FontAwesomeIcon style={{textAlign:'left'}}fontSize={10}icon={isOpen.stakeholders ? faChevronUp : faChevronDown} color={isOpen.stakeholders ?  '#2fd6ff':'white' } />
                  </span></>
                }
                id="stakeholders-nav-dropdown"
                className="custom-dropdown"
                show={isOpen.stakeholders}
                onClick={() => handleToggle('stakeholders')}
              >
                <NavDropdown.Item href="https://subra.ics.forth.gr/authorities/">Authorities</NavDropdown.Item>
                <NavDropdown.Item href="https://subra.ics.forth.gr/governmental_bodies/">Governmental Bodies</NavDropdown.Item>
                <NavDropdown.Item href="https://subra.ics.forth.gr/industry/">Industry</NavDropdown.Item>
                <NavDropdown.Item href="https://subra.ics.forth.gr/academia_research/">Academia & Research</NavDropdown.Item>
                <NavDropdown.Item href="https://subra.ics.forth.gr/citizens-groups/">Citizen Groups</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                  <span style={{color: isOpen.citizens ?  '#2fd6ff':'white',fontWeight:400 }} >Citizens <FontAwesomeIcon style={{textAlign:'left'}}fontSize={10}icon={isOpen.citizens ? faChevronUp : faChevronDown} color={isOpen.citizens ?  '#2fd6ff':'white' } /></span>

                  </>
                }
                id="citizens-nav-dropdown"
                className="custom-dropdown"
                show={isOpen.citizens}
                onClick={() => handleToggle('citizens')}
              >
                <NavDropdown.Item href="https://subra.ics.forth.gr/health-guidelines-and-recommendations/">Guidelines</NavDropdown.Item>
                <NavDropdown.Item href="https://subra.ics.forth.gr/emf-awareness-campaigns/">Campaigns</NavDropdown.Item>
                <NavDropdown.Item href="https://subra.ics.forth.gr/faq-section/">FAQ Section</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="http://subra.ics.forth.gr:3000/scientists">Scientists</Nav.Link>
              <Nav.Link href="http://subra.ics.forth.gr:3000/tools">Tools</Nav.Link>
              <Nav.Link href="http://subra.ics.forth.gr:3000/login?message=%22Please%20login%20to%20access%20this%20page.%22" ><span className="btn btn-login">Login</span></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar></div>
    );
};

export default AppHeader;
