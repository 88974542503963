import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Hero.css'; // Assuming you have a CSS file for custom styling

interface HeroProps {
  title: string;
  subtitle: string;
  backgroundImage: string;
}

const HeroComponent: React.FC<HeroProps> = ({ title, subtitle, backgroundImage }) => {
  return (
    <div className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Container className='hero-inner'>
      <Row className="justify-content-start text-left">
      <Col md={8}>
            <h1 className="hero-title">{title}</h1>
            <p className="hero-subtitle mt-0">{subtitle}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroComponent;
