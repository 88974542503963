
import React, { ReactNode } from 'react';
import AppHeader from '../shared/Header';
import AppContent from '../shared/Content';
import HeroComponent from '../shared/HeroComponent';
import Footer from '../shared/Footer';
import CopyrightComponent from '../shared/Copyright';
interface MainLayoutProps {
    children: ReactNode;
    menu: string; // Replace 'any' with the appropriate type for your menu prop
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
    return (
<div>
                <AppHeader />
                <HeroComponent
        title="Stakeholders"
        subtitle="Authorities"
        backgroundImage="https://subra.ics.forth.gr/wp-content/uploads/2024/07/Header-Static-BG.jpg" // Replace with your image URL
      />
                <AppContent>
                    {props.children}    </AppContent><Footer></Footer><CopyrightComponent></CopyrightComponent></div>

    );
};

export default MainLayout;
