import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown,faChevronUp,faChevronRight } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronDown);
library.add(faChevronUp);
library.add(faChevronRight);

function App() {
  return (
    <Router>
    <Routes />
</Router>
  );
}

export default App;
