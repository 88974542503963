import React from 'react';
import species from '../../assets/species.png';
import habitat from '../../assets/habitat.png';
import indicators from '../../assets/indicators.jpg';
import { Link } from 'react-router-dom';
import HeroComponent from '../shared/HeroComponent';

const Home: React.FC = () => {
    return (
        <div>

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam varius ex ut risus imperdiet dignissim. Vivamus egestas, risus ornare tempor ultricies, justo quam porta felis, et gravida diam mi et quam. Praesent id turpis at leo convallis malesuada a a orci. Sed quam massa, efficitur non elit sit amet, placerat imperdiet nunc. Integer eget erat consectetur, porttitor sapien vel, facilisis mi. Cras faucibus venenatis felis et accumsan. Nam urna turpis, pretium id vestibulum quis, sagittis id enim. Proin consequat sed eros et feugiat. Praesent erat felis, mattis non feugiat ut, congue eget augue. Sed in metus quis odio congue tincidunt ut vel quam.

Proin luctus sed lacus id vulputate. Nulla facilisi. Quisque id libero gravida, egestas libero in, vehicula dolor. Cras tincidunt gravida tortor, quis eleifend ex. Duis nibh urna, euismod et ullamcorper sed, bibendum in odio. Nulla pharetra lacinia tempor. Nunc ut felis nec lorem tempus lacinia et ac nibh. Sed non maximus turpis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Proin diam mauris, molestie ac cursus a, accumsan a urna. Maecenas convallis lacus eu aliquam luctus.

Sed mollis, quam vel ornare bibendum, risus ligula molestie lorem, sed lacinia tellus nisi et justo. In vitae faucibus massa. Integer neque tellus, maximus dictum vehicula nec, ullamcorper vel odio. Proin ultrices nunc quis urna pharetra, sed sodales massa luctus. Cras semper dolor quis turpis vulputate sagittis. Fusce vitae turpis justo. Nam finibus quis justo malesuada feugiat.

Aenean et fermentum metus. Vivamus eleifend ante vitae odio fringilla, vitae facilisis nunc luctus. Suspendisse non laoreet lorem. Donec quis sagittis tortor. Nullam vel porta ex. Cras convallis, purus at eleifend tristique, purus elit vulputate metus, nec tincidunt lorem dolor at velit. Donec in tortor consequat, tincidunt felis in, tempus orci. Cras eget accumsan turpis. Nam dictum porta ornare. Fusce efficitur, ante sed tincidunt ultrices, nisl ipsum ornare massa, sit amet faucibus orci eros at erat.

Sed a nulla ullamcorper, ultrices libero ut, consectetur ante. Nam viverra dignissim odio, at molestie sapien dictum a. Duis faucibus, ligula eget convallis rhoncus, est purus rutrum nunc, eget luctus tortor est ac leo. Proin magna est, finibus a felis et, vulputate hendrerit arcu. In facilisis metus gravida, ullamcorper sem sed, scelerisque urna. Etiam nec lectus sed ligula euismod tristique. Pellentesque cursus sagittis nisi nec porta. Cras orci eros, gravida a ipsum in, vulputate pretium felis. Aliquam eleifend augue sed metus ornare euismod. Nulla at leo non mi tincidunt efficitur at quis eros.

Fusce at neque sagittis, tristique metus eu, sagittis justo. Suspendisse vel ipsum at leo finibus pharetra ut ut quam. Vivamus a justo viverra, cursus libero vitae, pellentesque diam. Ut blandit efficitur magna id laoreet. Morbi sagittis luctus mauris, ac pharetra enim egestas in. Suspendisse feugiat dapibus blandit. Phasellus tempus urna et consectetur tristique. Vestibulum erat dolor, hendrerit vel sem sed, vestibulum auctor massa. Nunc tempus justo id ipsum finibus sodales. Nunc sit amet fringilla leo. Cras eget condimentum ante. Vivamus eleifend, dolor at vehicula laoreet, magna nisl congue urna, a bibendum neque augue eu quam.

Donec mauris velit, ornare sit amet arcu a, viverra egestas eros. Aliquam ut augue elit. Ut vel erat vitae felis ornare gravida. Suspendisse in risus ut eros rutrum dapibus. Cras malesuada turpis aliquet, ullamcorper mi id, efficitur tortor. Etiam magna nunc, feugiat sit amet placerat ac, rhoncus eu augue. Sed sit amet metus ligula. Nam in magna auctor, pharetra libero sed, scelerisque lorem. Integer consectetur turpis lectus, ac vehicula ligula rutrum molestie. Praesent pulvinar malesuada finibus.

Cras eros urna, pulvinar in condimentum a, sollicitudin ut est. Sed iaculis urna turpis. Nam ultricies laoreet felis, id pulvinar ligula fringilla nec. Aliquam non ipsum ante. Pellentesque condimentum viverra libero, quis interdum elit. Phasellus venenatis libero vehicula ipsum semper, condimentum sodales lacus aliquam. Morbi bibendum rutrum eros et semper. Nunc at faucibus tellus. Donec laoreet auctor feugiat.

Cras sed ligula purus. In tincidunt ligula ac quam condimentum ultrices. Quisque viverra massa ac quam consequat eleifend. Praesent vel ornare leo. Donec efficitur tellus vel magna tristique, ut laoreet nibh finibus. Donec a felis felis. In eleifend justo ut nulla gravida, at mollis ipsum rhoncus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec tellus ligula, efficitur eget enim eu, imperdiet condimentum justo. Fusce ullamcorper nulla ut lacinia gravida. Suspendisse in enim id orci commodo porttitor eu bibendum est. Fusce posuere nunc sit amet arcu porta, id vehicula dolor rhoncus. Integer quis magna cursus leo efficitur dapibus. Pellentesque ullamcorper interdum lectus bibendum placerat. Maecenas non erat id elit mollis lobortis.

Proin ultrices, nibh vel auctor consequat, ligula nisl pharetra tellus, tempor tristique quam nisi non enim. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam nec dictum tellus. Vivamus convallis, enim vel fringilla malesuada, quam turpis viverra mi, sed pretium odio diam sit amet libero. Morbi justo ex, bibendum in varius in, imperdiet sed orci. Vestibulum cursus eu elit ac tempor. Mauris sagittis felis sed ipsum molestie semper. Proin hendrerit aliquet nisi mattis ullamcorper. Etiam placerat est sit amet enim laoreet fringilla. Integer et gravida eros. Etiam vel nisi sollicitudin, iaculis odio quis, ornare lectus. Duis in nisi sed leo blandit pellentesque ut vel metus. Nam dignissim non tellus pellentesque euismod. Etiam dapibus lacus eget consectetur volutpat. Vivamus molestie sed lectus quis faucibus. Nam mattis a nibh non placerat.

Ut eget sapien purus. Aenean vitae facilisis mauris. Pellentesque lacus purus, interdum sit amet orci vel, sollicitudin lacinia nibh. Nunc quis elit mi. Vivamus a porttitor diam. Pellentesque ut quam ac est auctor tincidunt quis imperdiet leo. Morbi sollicitudin tempus dui, at porttitor nisi pulvinar et. Nulla facilisi. Nam et velit feugiat, porta justo nec, feugiat elit. Donec ultrices elit in lacus bibendum, et malesuada nisi pharetra. Duis mattis iaculis massa eu maximus. Nullam ac dui massa. Donec mi risus, bibendum sit amet posuere sit amet, consequat a nibh. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus convallis ipsum vitae mi vehicula placerat. Donec non nunc non tortor egestas ullamcorper.

In nunc ex, posuere et lobortis a, lacinia et eros. Ut elementum nibh neque. Ut imperdiet feugiat enim in accumsan. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Quisque lacinia id orci a bibendum. Proin non suscipit turpis, quis tempus sem. Aliquam erat volutpat. Nullam sit amet leo in sem suscipit sollicitudin nec sed urna. Donec vel mattis nunc.

Aenean ut interdum purus. Nulla laoreet accumsan tortor, a ultrices orci rhoncus non. Fusce ultricies augue vel leo ultricies ultricies. Etiam tempus lacus tortor, sit amet aliquam libero sagittis et. Cras sit amet semper nisl. Praesent finibus velit eget consequat egestas. Vestibulum nulla leo, tincidunt ut vestibulum a, posuere id tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut imperdiet porttitor libero at viverra. Nunc ac maximus orci, ut vulputate purus. Maecenas suscipit, est sed aliquet cursus, elit lorem sodales mi, id suscipit orci tortor aliquet augue. In egestas ac nisi sit amet tincidunt. Cras tempus arcu quis nisl mattis varius. Cras purus metus, suscipit quis diam nec, vestibulum blandit arcu. Nam nec nibh condimentum, vestibulum enim in, dapibus libero.

Nam feugiat consequat felis, id tincidunt mi tempor vel. Aenean tortor lacus, malesuada vel volutpat ut, euismod sed tortor. Nunc et eleifend ligula, at ornare velit. Aenean pellentesque velit sit amet urna cursus sodales. Aenean nec interdum dolor. Ut interdum porta dui, id posuere leo luctus sed. Duis non turpis nibh. Integer fringilla lorem et quam lacinia, sit amet euismod magna blandit.

Integer cursus vehicula arcu ut malesuada. Nunc nec scelerisque nisi, nec aliquet arcu. Sed viverra felis non tempor porttitor. Nulla molestie, ex eu iaculis suscipit, velit justo congue magna, nec ullamcorper ante nibh vel diam. Donec facilisis sem nec nulla porttitor aliquam. Aliquam erat volutpat. Donec nec lobortis ante. Curabitur vitae magna magna. Proin luctus, elit eget tincidunt eleifend, mauris enim ullamcorper odio, eu faucibus ante nisl ut velit. Donec semper ultrices quam, et tempus velit. Proin sed finibus justo, at scelerisque enim.
        </div>
    );
};

export default Home;
