import React, { ReactNode } from 'react';

interface AppContentProps {
    children: ReactNode;
}


const AppContent: React.FC<AppContentProps> = ({ children }) => (
    <main className="content-inner" style={{ }}>
        <div
            style={{
                padding: 24,
                minHeight: 360,
                borderRadius: 16,
            }}
        >       
            {children}
        </div>
    </main>
);

export default AppContent;
