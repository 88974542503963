import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Copyright.css'; // Assuming you have a CSS file for custom styling

const CopyrightComponent: React.FC = () => {
  return (
    <div className='copyright'>
      <Container>
        <Row>
          <Col md={6}>
            <p>
              Copyright © {new Date().getFullYear()} NIKH
            </p>
          </Col>
          <Col md={6} className="text-md-end">
            <a href="https://www.nextgem.eu/"  target="_blank" rel="noreferrer">

              <p >Designed and developed by NextGEM</p>
            </a>
          </Col>
        </Row>
      </Container></div>
  );
};

export default CopyrightComponent;
